<template>
  <div>
    <div class="d-block d-sm-none" style="background-color: #f5f5f5;">
      <b-card
        v-for="(order, key) of orders"
        :key="key"
        class="mb-2"
        @click="$router.push({ name: 'commandeDetail', params: { id: order.order.number } })"
      >
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="d-flex font-weight-bolder">
              <span class="col-8">{{ `${order.order.number} - ${order.order.shipping.first_name} ${order.order.shipping.last_name}` }}</span>
              <div class="col-4">
                <feather-icon
                  v-if="resolveStatusIcon(order.order.status)"
                  :icon="resolveStatusIcon(order.order.status)"
                  class="ml-auto"
                  size="32"
                  :class="`text-${resolveStatusIconColor(order.order.status)}`"
                />
                <b-badge
                  v-else
                  pill
                  :variant="`light-primary`"
                  class="text-capitalize"
                >
                  {{ resolveStatusTranslation(order.order.status) }}
                </b-badge>
                <img
                  style="width: 30px; margin-left: 10px;"
                  :src="require(`@/assets/images/flags/${imageCountry(order.order.number.slice(0, 2))}`)"
                >
              </div>
            </b-card-title>
            <b-card-sub-title class="mb-25">
              {{ order.order.total }}€ - {{ $moment(order.order.date_created).format('HH:mm / DD-MM-YYYY') }}
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-text class="mt-1">
          <ul>
            <li
              v-for="(item, keyI) of order.order.line_items"
              :key="keyI"
            >
              {{ item.quantity }} x {{ item.name }}
            </li>
          </ul>
          <p v-if="order.order.coupon_lines && order.order.coupon_lines.length">
            <strong>Code promo:</strong> {{ order.order.coupon_lines.map(c => c.code).join(' / ') }}
          </p>
          <p v-if="order.order.payment_method_title">
            <strong>Paiement:</strong> {{ order.order.payment_method_title }}
          </p>
          <p v-if="order.system.sources && order.system.sources.length">
            <strong>Source:</strong> {{ order.system.sources[0].source }} - {{ order.system.sources[0].campaign }}
          </p>
        </b-card-text>
        <b-link
          class="card-link d-flex justify-content-end"
          @click="$router.push({ name: 'commandeDetail', params: { id: order.order.number } })"
        >
          Voir la commande
        </b-link>
      </b-card>
    </div>
    <div class="d-none d-sm-block">
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="orders"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Aucune commande trouvée"
        >

          <!-- Column: User -->
          <template #cell(order)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :variant="`light-${resolveUserStatusVariant(data.item.order.shipping.type)}`"
                  :to="{ name: 'commandeDetail', params: { id: data.item.order.number } }"
                />
              </template>
              <b-link
                :to="{ name: 'commandeDetail', params: { id: data.item.order.number } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.order.shipping.first_name }} {{ data.item.order.shipping.last_name }}
              </b-link>
              <small class="text-muted">{{ data.item.order.number }}</small>
            </b-media>
          </template>

          <!-- Column: Status -->
          <template #cell(price)="data">
            <b-badge
              pill
              class="text-capitalize bg-secondary bg-lighten-3"
            >
              {{ data.item.order.number.slice(0, 2) === 'DK' ? (data.item.order.total * 0.13).toFixed(2) : data.item.order.total }} {{ data.item.order.number.slice(0, 2) === 'UK' ? '£' : '€' }}
            </b-badge>
            <b-badge
              v-if="data.item.order.number.slice(0, 2) === 'UK'"
              pill
              class="text-capitalize bg-primary"
            >
              ~ {{ (data.item.order.total * 1.17).toFixed(2) }} €
            </b-badge>
            <b-badge
              v-if="data.item.system._sellerId"
              pill
              class="text-capitalize bg-primary"
            >
              DASHBOARD
            </b-badge>
            <br>
            <b-badge
              v-if="Number(data.item.order.total) === 0 && data.item.order.coupon_lines && data.item.order.coupon_lines.length"
              :variant="`light-${data.item.order.coupon_lines.find(c => c.code.includes('influ')) ? 'primary' : data.item.order.coupon_lines.find(c => c.code.includes('savvoucher')) ? 'warning' : 'secondary'}`"
              pill
              class="text-capitalize mt-1"
            >
              {{ data.item.order.coupon_lines.find(c => c.code.includes('influ')) ? 'INFLU' : data.item.order.coupon_lines.find(c => c.code.includes('savvoucher')) ? 'SAV' : data.item.order.coupon_lines[0].code }}
            </b-badge>
          </template>

          <template #cell(status)="data">
            <feather-icon
              v-if="resolveStatusIcon(data.item.order.status)"
              :icon="resolveStatusIcon(data.item.order.status)"
              class="ml-auto"
              size="32"
              :class="`text-${resolveStatusIconColor(data.item.order.status)}`"
            />
            <b-badge
              v-else
              pill
              :variant="`light-primary`"
              class="text-capitalize"
            >
              {{ resolveStatusTranslation(data.item.order.status) }}
            </b-badge>
            <b-badge
              v-if="data.item.system.dateShipping"
                pill
                :variant="`light-secondary`"
                class="text-capitalize" >
                {{ $moment(data.item.system.dateShipping).format('DD-MM-YYYY') }}
              </b-badge>
          </template>

          <template #cell(date)="data">
            <span>
              {{ $moment(data.item.order.date_created).format('HH:mm / DD-MM-YYYY') }}
            </span>
          </template>

          <template #cell(system)="data">
            <span>
              {{ $moment(data.item.system.dateShipping).format('DD-MM-YYYY') }}
            </span>
          </template>

          <template #cell(product)="data">
            <span
              v-b-tooltip.hover
              :title="data.item.order.line_items.map(item => `${item.quantity} x ${item.name}`).join('\n')"
              variant="gradient-primary"
            >
              {{ data.item.order.line_items.map(l => l.quantity).reduce((a, b) => a + b, 0) > 1 ? `${data.item.order.line_items.map(l => l.quantity).reduce((a, b) => a + b, 0) } articles` : `${data.item.order.line_items.map(l => l.quantity).reduce((a, b) => a + b, 0) } article` }}
            </span>
          </template>

          <template #cell(country)="data">
            <img
              style="width: 30px;"
              :src="require(`@/assets/images/flags/${imageCountry(data.item.order.number.slice(0, 2))}`)"
            >
          </template>
          <template #cell(sources)="data">
            <p
              v-if="data.item.system.sources && data.item.system.sources.length"
              v-b-tooltip.hover
              :title="`${data.item.system.sources[0].source} - ${data.item.system.sources[0].campaign}`"
            >
              {{ data.item.system.sources[0].source }} - {{ data.item.system.sources[0].campaign }}
            </p>
          </template>
          <!-- <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="ClipboardIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="$router.push({ name: 'factureDetail', params: { id: data.item.order.number } })"
              />
              <b-tooltip
                title="Facture"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              />
            </div>
          </template> -->

        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BTable, BMedia,
  BBadge, BAvatar, BLink, VBTooltip, BCardText, BCardTitle, BCardHeader, BCardSubTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BCardTitle,
    BCardHeader,
    BCardSubTitle,
    // BTooltip,
    BCardText,
    // vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      default: null,
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: 'setColumn',
    },
  },
  data() {
    return {
      tableColumns: [],
      avatarText,
    }
  },
  async mounted() {
    console.log('change filter')
    const tableColumns = [
      { key: 'order', label: 'Client', sortable: true },
      { key: 'price', label: 'Montant', sortable: true },
      { key: 'status', label: 'Statut', sortable: true },
      { key: 'date', label: "Date d'achat", sortable: true },
      { key: 'product', label: 'Produits', sortable: true },
      { key: 'country', label: 'Pays', sortable: true },
      { key: 'sources', label: 'Sources', sortable: true },
    ]
    this.tableColumns = tableColumns
  },
  methods: {
    async setColumn() {
      if (this.filter?.$and?.map(f => Object.entries(f))?.map(f => f[0])?.filter(f => f[0] === 'order.status' && f[1] === 'waiting-delivery')?.length) {
        const tableColumns = [
          { key: 'order', label: 'Client', sortable: true },
          { key: 'price', label: 'Montant', sortable: true },
          { key: 'status', label: 'Statut', sortable: true },
          { key: 'system', label: 'Date de livr.', sortable: true },
          { key: 'date', label: "Date d'achat", sortable: true },
          { key: 'product', label: 'Produits', sortable: true },
          { key: 'country', label: 'Pays', sortable: true },
        ]
        this.tableColumns = tableColumns
      }
    },
    resolveUserStatusVariant(type) {
      if (type === 'admin') return 'success'
      if (type === 'user') return 'secondary'
      return 'primary'
    },
    imageCountry(type) {
      if (type === 'FR') return 'france.svg'
      if (type === 'DE') return 'allemagne.svg'
      if (type === 'ES') return 'espagne.svg'
      if (type === 'IT') return 'italie.svg'
      if (type === 'PT') return 'portugal.svg'
      if (type === 'EN') return 'en.png'
      if (type === 'UK') return 'royaume-uni.png'
      if (type === 'NL') return 'netherlands.png'
      if (type === 'DK') return 'denmark.png'
      return 'france.svg'
    },
    resolveStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'sended') return 'secondary'
      if (status === 'failed') return 'warning'
      if (['cancelled', 'on-hold'].includes(status)) return 'danger'
      return 'info'
    },
    resolveStatusIconColor(status) {
      switch (status) {
        case 'processing':
          return 'success'
        case 'pending':
          return 'success'
        case 'on-hold':
          return 'warning'
        case 'completed':
          return 'warning'
        case 'failed':
          return 'danger'
        case 'cancelled':
          return 'primary'
        case 'refunded':
          return 'primary'
        default:
          return false
      }
    },
    resolveStatusIcon(status) {
      switch (status) {
        case 'processing':
          return 'ToolIcon'
        case 'pending':
          return 'ClockIcon'
        case 'on-hold':
          return 'MinusCircleIcon'
        case 'completed':
          return 'CalendarIcon'
        case 'failed':
          return 'AlertCircleIcon'
        case 'cancelled':
          return 'XCircleIcon'
        case 'refunded':
          return 'RepeatIcon'
        default:
          return false
      }
    },
    resolveStatusTranslation(status) {
      switch (status) {
        case 'processing':
          return 'En fabrication'
        case 'pending':
          return 'En attente de paiement'
        case 'on-hold':
          return 'En attente'
        case 'sended':
        case 'completed':
          return 'Expédiée'
        case 'failed':
          return 'Echouée'
        case 'cancelled':
          return 'Annulée'
        case 'refunded':
          return 'Remboursée'
        case 'rembourse-partiel':
          return 'Remboursée partiellement'
        default:
          return status
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
