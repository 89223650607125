<template>

  <div>
    <b-card>
      <b-row>

        <!-- Per Page -->
        <!-- <b-col
          cols="1"
          md="1"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Filtres:</label>

        </b-col> -->

        <!-- Search -->
        <b-col
          cols="12"
          md="11"
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Recherche (Nom, pénom, email, n° commande...)"
                @keyup.native.enter="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mt-2 mt-md-0"
            >
              <flat-pickr
                v-model="rangeDate"
                placeholder="Date"
                class="form-control mr-1"
                :config="{ mode: 'range'}"
                @input="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mt-2 mt-md-0"
            >
              <v-select
                v-model="status"
                multiple
                placeholder="Statut"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="statusOptions"
                @input="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mt-2 mt-md-0 ml-1"
            >
              <v-select
                v-model="paymentType"
                multiple
                placeholder="Paiement"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="Paiement"
                :options="paymentOptions"
                @input="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mt-2 mt-md-0 ml-1"
            >
              <v-select
                v-model="shop"
                multiple
                placeholder="Shop"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="Shop"
                :options="shopOptions"
                @input="setQuery"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              cols="12"
              md="3"
            >
              <b-form-input
                v-model="postalCode"
                class="d-inline-block mr-1"
                placeholder="Code postal / département"
                @keyup.native.enter="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-input
                v-model="phoneNumber"
                class="d-inline-block mr-1"
                placeholder="Numéro de téléphone (+336......)"
                @keyup.native.enter="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="productList"
                placeholder="Produit"
                label="name"
                :options="productSelect"
                :reduce="product => product.name"
                :clearable="false"
                :multiple="true"
                class="invoice-filter-select"
                transition=""
                @input="setQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mt-2"
            >
              <v-select
                v-model="knowsList"
                placeholder="Connu par"
                label="name"
                :options="knowsFR"
                :clearable="false"
                :multiple="true"
                class="invoice-filter-select"
                transition=""
                @input="setQuery"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip,
  BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      status: '',
      shop: '',
      paymentType: '',
      postalCode: '',
      productSelect: [],
      phoneNumber: '',
      productList: [],
      knowsList: [],
      rangeDate: null,
      searchQuery: '',
      knowsFR: ['Publicité sur internet', 'Moteur de recherche (Google, Bing...)', 'Facebook', 'Instagram', 'Youtube', 'Blog', 'Dans la presse', 'A la télévision', 'Publicité dans le métro', 'Boutique - 4ème arrondissement de Paris', 'Boutique - Lyon', 'BHV MARAIS', 'Bouche-à-oreille', 'Affichage de rue', 'Je ne sais pas / Autres'],
      statusOptions: [
        'processing',
        'pending',
        'on-hold',
        'completed',
        'waiting-delivery',
        'waiting-payment',
        'failed',
        'cancelled',
        'refunded',
        'rembourse-partiel',
        'sended',
      ],
      paymentOptions: [
        'mollie_wc_gateway_banktransfer',
        'other',
        'mollie_wc_gateway_mybank',
        'cheque',
        'klarnapaylater',
        'payzenstd',
        'sofincosignature',
        'stripe',
        'bhv',
        'klarnasliceit',
        'cheques_kipli_gateway',
        'scalapay_gateway',
        'mollie_wc_gateway_creditcard',
        'paypal',
        'scalapay',
        'mollie_wc_gateway_klarnapaylater',
        'wcpaygreen',
        'espece',
        'sepa',
        'payzenmulti',
        'bacs',
        'offline_gateway',
        'mollie_wc_gateway_klarnasliceit',
      ],
      shopOptions: [
        'FR',
        'CH',
        'AT',
        'IE',
        'BE',
        'LU',
        'IT',
        'ES',
        'DE',
        'PT',
        'EN',
        'NL',
        'UK',
        'DK',
        'BB',
      ],
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  watch: {
    // rangeDate: {
    //   handler: 'setQuery',
    //   deep: true,
    // },
  },
  async mounted() {
    await this.get()
    if (this.$route.query?.name) {
      this.searchQuery = this.$route.query?.name
    }
    if (this.$route.query?.phoneNumber) {
      this.phoneNumber = this.$route.query?.phoneNumber
    }
    if (this.$route.query?.shop) {
      this.shop = this.$route.query?.shop?.includes(',') ? this.$route.query?.shop?.split(',') : [this.$route.query?.shop]
    }
    if (this.$route.query?.status) {
      this.status = this.$route.query?.status?.includes(',') ? this.$route.query?.status?.split(',') : [this.$route.query?.status]
    }
    if (this.$route.query?.name) {
      this.searchQuery = this.$route.query?.name
    }
    if (this.$route.query?.postalCode) {
      this.postalCode = this.$route.query?.postalCode
    }
    if (this.$route.query?.created_at?.includes('to')) {
      this.rangeDate = this.$route.query?.created_at
    }
    const { products } = await this.$http.get('/admin/blog/list-product/?country=all')
    this.productSelect = products
    this.setQuery()
  },
  methods: {
    ...mapActions('admin', ['get']),
    async setQuery() {
      const filter = {}
      const $and = []
      let query = {}
      console.log(this.shop, 'fzqdsfqs')
      if (this.searchQuery && this.searchQuery !== '') {
        query = { ...query, name: this.searchQuery }
        this.$router.replace({ query })
      }
      if (this.phoneNumber && this.phoneNumber !== '') {
        $and.push({ 'order.meta_data': { $elemMatch: { key: '_shipping_phone', value: this.phoneNumber } } })
        query = { ...query, phoneNumber: this.phoneNumber }
        this.$router.replace({ query })
      }
      if (this.knowsList && this.knowsList.length) {
        $and.push({ 'order.meta_data': { $elemMatch: { key: '_shipping_refer_to', value: { $in: this.knowsList } } } })
        query = { ...query }
        this.$router.replace({ query })
      }
      if (this.$route.query?.pagination) {
        query = { ...query, pagination: this.$route.query?.pagination }
      }
      if (this.postalCode && this.postalCode !== '') {
        // eslint-disable-next-line no-undef, quotes, prefer-template
        $and.push({ 'order.shipping.postcode': { $regex: "^" + this.postalCode } })
        query = { ...query, postalCode: this.postalCode }
        this.$router.replace({ query })
      }
      if (this.status && this.status.length) {
        $and.push({ 'order.status': { $in: this.status } })
        query = { ...query, status: this.status }
        this.$router.replace({ query })
      }
      if (this.productList && this.productList.length) {
        $and.push({ 'order.line_items': { $elemMatch: { name: { $in: this.productList } } } })
        // query = { ...query, status: this.status }
        // this.$router.replace({ query })
      }
      if (this.shop && this.shop.length) {
        // $and.push({ shop: { $in: this.shop } })
        $and.push({ 'order.shipping.country': { $in: this.shop } })
        query = { ...query, shop: this.shop }
        this.$router.replace({ query })
      }
      if (this.rangeDate && this.rangeDate.includes('to')) {
        const dates = this.rangeDate.split(' to ')
        $and.push({ created_at: { $gte: this.$moment(dates[0]).startOf('days').toDate().getTime(), $lte: this.$moment(dates[1]).endOf('days').toDate().getTime() } })
        query = { ...query, created_at: this.rangeDate }
      }
      if (this.paymentType && this.paymentType.length) {
        $and.push({ 'order.payment_method': { $in: this.paymentType } })
        query = { ...query, paymentType: this.paymentType }
        this.$router.replace({ query })
      }
      if ($and.length) {
        filter.$and = $and
      }
      this.$router.replace({ query })
      this.$emit('change', filter, this.searchQuery)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
